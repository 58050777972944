import React, { Component, useState, useTransition } from 'react';

import { reactLocalStorage } from 'reactjs-localstorage';
import kinyoodeeIcon from '../../../assets/img/kinyoodeeIcon.png'
import englishIcon from '../../../assets/img/eng_language.png'
import thaiIcon from '../../../assets/img/thai_language.png'
import { Link } from 'react-router-dom';

class Topbar extends Component {
    
    logout() {
        reactLocalStorage.clear();
    }
    
    render() {    

        const profileName = reactLocalStorage.get("profile_name");
        const languageCheck = reactLocalStorage.get("language");


        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                {/* <!-- Topbar Navbar --> */}
                <ul className="navbar-nav">

                    {/* <!-- Nav Item - Alerts --> */}
                    <div>
                        <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="img-profile rounded-circle" src={kinyoodeeIcon} />
                                <span className="mr-2 ml-2 text-gray-600 larg">KinYoodee</span>
                            </a>
                        </li>
                    </div>
                </ul>
                
                <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown no-arrow pl-2">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img className="imageLanguage img-profile rounded-circle" src={languageCheck == 'th' ? thaiIcon: englishIcon} />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                <a className="text-gray-800 nav-link" href='' onClick={() => { 
                                    reactLocalStorage.set("language","en"); 
                                }}>
                                    <img className="img-profile rounded-circle" src={englishIcon} />
                                    <h5 className="pt-2 pl-3" aria-labelledby="userDropdown">English</h5>
                                </a>
                                <a className="text-gray-800 nav-link" href='' onClick={() => { 
                                    reactLocalStorage.set("language","th") ;
                                }}>
                                    <img className="img-profile rounded-circle" src={thaiIcon} />
                                    <h5 className="pt-2 pl-3" aria-labelledby="userDropdown">Thai</h5>
                                </a>
                            </div>
                        </li>
                </ul>
                

                <ul className="navbar-nav">
                    <div className="topbar-divider d-none d-sm-block"></div>

                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 text-gray-600 small">{profileName}</span>
                        </a>
                        {/* <!-- Dropdown - User Information --> */}

                        <Link to='/' >
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown" onClick={this.logout}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout
                            </div>
                        </Link>
                    </li>

                </ul>

            </nav>
            
        )
    }
}

export default Topbar;