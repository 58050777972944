import React, { Component } from "react";
import Chart from "react-google-charts";
import CardBasic from "../../Cards/Basic";

// const pieData = [
//   ['Task', 'Hours per Day'],
//   ['Work', 11],
//   ['Eat', 2],
//   ['Commute', 2],
//   ['Watch TV', 2],
//   ['Sleep', 7],
// ]
const pieOptions = {
  //title: 'My Daily Activities',s
  // pieSliceText: 'value',
  pieSliceText: "none",
  pieHole: 0.6,
  legend: "none",
  options: {
    legend: {
      display: false,
    },
  },
  colors: ["#BFC9CA", "#E85651"],
};

const text = "Drug";
class PieChart extends Component {
  constructor(props) {
    super(props);
    this.width = "100%";
    this.height = "100%";
    this.chartType = "PieChart";
    // this.pieData = pieData;
    this.pieOptions = pieOptions;
    this.text = text;
  }

  render() {
    if (this.props.loading) {
      return (
        <CardBasic>
          <Chart
            text={this.text}
            width={this.props.width || this.width}
            height={this.props.height || "320px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={this.props.pieData || this.pieData}
            options={pieOptions}
            rootProps={{ "data-testid": "3" }}
          />
          <div id="labelOverlay">
            <p className="used-size">{this.props.riskDesc}</p>
          </div>
          <h3 style={{ textAlign: "center" }}>{this.props.text}</h3>
        </CardBasic>
      );
    }
    return <CardBasic></CardBasic>;
  }
}
export default PieChart;
