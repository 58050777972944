import React, { useEffect , useState } from "react";
import { useLocation, Link} from "react-router-dom";

//Navigation
import Topbar from "../../components/Navigation/Topbar/index";
import CardBasic from "../../components/Cards/Basic";
import InfoIcon from "../../components/Icon/Info/index";
import ChevLeftIcon from "../../components/Icon/ChevLeft/index";
import Star from "../../components/Rating/Star"
import Footer from "../Footer/index"
import GeneDetail from "../../components/GeneList"
import { reactLocalStorage } from "reactjs-localstorage";

function GeneList() {
  const location = useLocation();
  const data = location.state?.data;

  var languageCheck = reactLocalStorage.get("language");
  const [nameDrug, setNameDrug] = useState([]);
  const [resultDrug, setResultDrug] = useState([]);



  data.gene_list.forEach((value, index) => {
    value.key = index;
    value.evidence = <Star count={5} value={value.evidence}></Star>;
  });

  useEffect(() => {
    languageCheck === 'th' ? setAllThaiLanguage() : setAllEnglishLanguage();
  },[])

  function setAllThaiLanguage() {
    setNameDrug(data.drug_name_th);
    setResultDrug(data.result_th);
  }

  function setAllEnglishLanguage() {
    setNameDrug(data.drug_name_en);
    setResultDrug(data.result);
  }
  

  return (
    <div>
      {/* <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <Topbar />
            {/* <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}

              {/* <PageHeading title={data.drug_name_en} /> */}
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <CardBasic>
                  <div className="row ">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    <Link to='/dashboard' ><ChevLeftIcon desc=""></ChevLeftIcon></Link>
                    </div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 text-center">
                      <h3 style={{color:"#4E4B4B"}}>{nameDrug}</h3>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                    </div>
                  </div>
                  </CardBasic>
                </div>
              </div>
              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <CardBasic title={<InfoIcon desc={nameDrug}></InfoIcon>}>
                  {(() => {
                    if (resultDrug) {
                      return (
                        <div className="mb-4">{resultDrug}</div>
                      )
                    }
                  })()}
                  <GeneDetail data={data} language={languageCheck} />                    
                    {/* <ExpandTable keyField="gene" datas={data.gene_list} columns={columns}></ExpandTable> */}
                  </CardBasic>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <Footer/>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
}

export default GeneList;
